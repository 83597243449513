<template>
  <el-dialog title="新增授权公司" :visible.sync="dialogVisible" width="600px" :destroy-on-close="true"
    :close-on-click-modal="false" :append-to-body="true">
    <p class="tip"><i class="el-icon-warning-outline"></i>为了保证您的权益得到保障,请务必填写真实信息</p>
    <el-form :model="ruleForm" :rules="rules" @submit.native.prevent ref="ruleForm" label-width="80px">
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="ruleForm.name" maxlength="50" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="纳税号" prop="id_card">
        <el-input v-model="ruleForm.id_card" placeholder="请输入企业纳税号"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="ruleForm.address" maxlength="50" placeholder="请输入您的详细地址"></el-input>
      </el-form-item>
      <el-form-item label="法人" prop="legal_name">
        <el-input v-model="ruleForm.legal_name" placeholder="请输入公司法人姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="ruleForm.phone" placeholder="请输入您的手机号"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="submitLoading">保存企业信息</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { fetchAddContractUser } from '@/api/user'
import { taxIdentificationNum, phoneNumber } from '@/utils/regular'
export default {
  props: {
    goodsType: {
      type: [String, Number],
      default: ''
    }
  },
  watch: {
    dialogVisible(v) {
      if (!v) {
        this.ruleForm = JSON.parse(JSON.stringify(this.clearForm))
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      ruleForm: {
        name: '',
        id_card: '',
        address: '',
        legal_name: '',
        phone: ''
      },
      clearForm: {
        name: '',
        id_card: '',
        address: '',
        legal_name: '',
        phone: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入您的详细地址', trigger: 'blur' }]
      },
    };
  },
  methods: {
    showDia() {
      this.dialogVisible = true
    },
    // 格式校验
    formatVerify() {
      let rule = true
      if (this.ruleForm.id_card) {
        if (!taxIdentificationNum.test(this.ruleForm.id_card)) {
          rule = false
          this.$message.error('纳税号填写格式有误')
        } else {
          rule = true
        }
      }
      if (this.ruleForm.phone) {
        if (!phoneNumber.test(this.ruleForm.phone)) {
          rule = false
          this.$message.error('手机号号填写格式有误')
        } else {
          rule = true
        }
      }
      return rule
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.formatVerify()) {
            this.addContract()
          }
        }
      });
    },
    async addContract() {
      try {
        this.submitLoading = true
        const res = await fetchAddContractUser(this.getAddParams())
        if (res.code === 0) {
          this.dialogVisible = false
          this.$emit('getList')
          this.$message.success('保存成功！')
        }
        this.submitLoading = false
      } catch (error) {
        this.submitLoading = false
        console.log(error)
      }
    },
    getAddParams() {
      const params = {
        type: this.goodsType,
        ...this.ruleForm
      }
      return params
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog {
  .el-dialog__header {
    padding: 24px 36px 12px;
    border-bottom: 1px solid #F5F6FA;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }

  .el-dialog__body {
    padding: 19px 60px 22px;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 60px 32px;

    .el-button {
      height: 36px;
      border-radius: 2px;
      border: none;
      font-weight: 400;
      font-size: 14px;
      min-width: 0;
    }

    .el-button--default {
      width: 70px !important;
      background: #F2F2F2;
      color: rgba(0, 0, 0, 0.5);
    }

    .el-button--primary {
      width: 124px !important;
      background: #E52A0D;
    }
  }
}

.tip {
  @include size(100%, 32px);
  background: rgba(255, 142, 20, 0.1);
  border: 1px solid #FF8E14;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-bottom: 24px;

  i {
    color: #FF8E14;
    margin-right: 4px;
  }
}

/deep/.el-form {
  .el-form-item {
    margin-bottom: 15px;
  }

  .el-form-item__label {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
  }

  .el-form-item__content {
    height: 32px;
  }

  .el-input {
    height: 100%;

    input {
      height: 100%;
      background: #F5F6FA;
      border-radius: 2px;
      border: none;

      &::placeholder {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>