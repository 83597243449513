<template>
  <el-dialog
    :visible="dialogVisibleAuth"
    class="dialog-self"
    :destroy-on-close="true"
    append-to-body
    :show-close="false"
    :before-close="handleCancel"
    center
  >
    <!-- <div class="dialog-title-self" slot="title">
      <div class="title">提示</div>
    </div> -->
    <div class="tips-container">
      <div class="tips-text">
        <p class="title">尊敬的PlayList会员用户：</p>
        <p class="tips">感谢您支持版权事业，支持正版，人人有责。</p>
        <p class="tips">PlayList将继续为您提供高质量正版素材！</p>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCancel">好的</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AuthDialog',
  data() {
    return {
      
    }
  },
  props:{
    dialogVisibleAuth: {
      type: Boolean,
      default: false
    },
    certifcateNum: {
      type: Number
    }
  },
  methods: {
    /**
     * 关闭弹框
     */
    handleCancel() {
        this.$store.dispatch('download/updateAuthDialogvisible', {
          dialogVisibleAuth: false
        })
    },
    /***
     * 开通/升级会员
     */
    openMember(){
        this.$router.push({ path: '/package/page' });
        this.handleCancel();
    }
  }
}
</script>

<style lang="scss" scoped>

.tips-container {
  color: rgba(51, 51, 51, 1);
  padding-left: 0px;
  margin-bottom: 10px;
  word-break: break-all;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  .tips-text{
    .title{
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
      background: linear-gradient(90deg, #CD8548 0%, #CE9A6C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-bottom: 16px;
    }
    .tips{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.75);
      line-height: 28px;
      text-indent: 36px;
      label{
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #3D4666;
        line-height: 28px;
        text-shadow: 0px 1px 1px rgba(32, 48, 83, 0.2);
        background: linear-gradient(90deg, #DAA14F 0%, #FEC587 61%, #E2B353 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
/deep/.el-dialog{
    width: 520px!important;
    height: 350px!important;
    background: url('~@/assets/image/auth_bg.png') no-repeat;
    background-size: contain;
    padding: 30px 60px 0px 63px;
    box-shadow: none!important;
    .el-dialog__header{
      padding: 0px!important;
      .el-dialog__headerbtn,.el-dialog__header{
        font-size: 25px!important;         
      }
    }
    .el-dialog__body{
      padding: 0px!important;  
    }
    .el-dialog__footer{
      margin-top: 78px;
      text-align: center;  
      .el-button--primary{
        border-color: transparent!important;
        width: 160px;
        height: 40px;
        background: linear-gradient(90deg, #FCDDA2 0%, #E1AB63 100%);
        border-radius: 20px;
        span{
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #213053;
          line-height: 25px;
          letter-spacing: 2px;
          background: linear-gradient(90deg, #A05D24 0%, #A56329 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
}
</style>
