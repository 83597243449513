<template>
  <div class="activate-wrap">
    <ul class="formDate" v-if="JSON.stringify(info) !== '{}'">
      <li class="row">
        <span class="label">会员名称：</span>
        <span class="value">{{ `${info.goodsType === 1 ? '个人' : '企业'}${info.goods_name}` }}</span>
      </li>
      <li class="row">
        <span class="label">会员有效期：</span>
        <span class="value" v-if="vipInfo">{{ `${formatDate(vipInfo.start_at.replace(/-/g, '/'),
          'yyyy-MM-dd')} 至 ${formatDate(vipInfo.end_at.replace(/-/g, '/'), 'yyyy-MM-dd')}` }}</span>
      </li>
      <li class="row" v-if="info.goodsType === 2">
        <span class="label">可授权公司数：</span>
        <span class="value" v-if="vipInfo">{{ `${vipInfo.contracts.length}/${vipInfo.cert_num}` }}</span>
        <span class="value" v-else>-</span>
      </li>
      <li class="row">
        <span class="label">当日剩余授权/下载次数：</span>
        <span class="value" v-if="vipInfo">{{ vipInfo.down_num_remain }}</span>
        <span class="value" v-else>-</span>
      </li>
    </ul>
    <div class="select-wrap">
      <span class="label">授权信息</span>
      <div class="flexEnd">
        <el-checkbox-group v-model="auth_scene">
          <el-checkbox v-for="item in authSceneOptions" :key="item.sk_id" :label="item.sk_id">{{ item.sk_name }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>

    <!-- 企业 -->
    <div class="select-wrap" v-if="info.goodsType === 2">
      <span class="label">请选择授权主体公司：</span>
      <div class="flexEnd">
        <el-radio-group v-model="conuser_id">
          <!-- {{ `${item.name}${item.is_rel === 1 ? '(待签署)' : ''}` }} -->
          <el-radio :label="item.conuser_id" v-for="(item, idx) in subjectList" :key="idx"
            :class="item.is_rel === 1 ? 'redText' : ''">{{ item.name }}</el-radio>
          <el-button type="text" class="add-btn" @click="handleAdd"
            v-if="!(vipInfo.contracts.length === vipInfo.cert_num)">新增授权公司</el-button>
        </el-radio-group>
        <p class="tip redText" v-if="!(vipInfo.contracts.length === vipInfo.cert_num)">* 标红企业信息为当前会员绑定激活企业</p>
      </div>
    </div>
    <!-- 个人 -->
    <ul class="formDate" v-if="info.goodsType === 1">
      <template v-if="subjectList.length > 0">
        <li class="row">
          <span class="label">姓名：</span>
          <span class="value">{{ subjectList[0].name || '-' }}</span>
        </li>
        <li class="row">
          <span class="label">身份证号码：</span>
          <span class="value">{{ subjectList[0].id_card || '-' }}</span>
        </li>
      </template>
      <template v-else>
        <div class="rowShow">
          <el-form :model="ruleForm" :rules="rules" @submit.native.prevent ref="ruleForm">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="ruleForm.name" size="small" placeholder="请输入被授权人姓名"></el-input>
            </el-form-item>
            <el-form-item label="身份证号码：" prop="id_card">
              <el-input v-model="ruleForm.id_card" size="small" placeholder="请输入被授权身份证号码"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="text" class="add-btn" @click="saveRuleForm" :loading="saveLoading">保存</el-button>
        </div>
      </template>
    </ul>
    <!-- 新增授权公司弹窗 -->
    <addDigitude ref="DigitudeRef" @getList="getList" :goodsType="info && info.goodsType">
    </addDigitude>
  </div>
</template>

<script>
import { fetchUserList, fetchUserVipInfo } from '@/api/user'
import { formatDate } from '@/utils/utils'
import addDigitude from './addDigitude.vue'
import { fetchAddContractUser } from '@/api/user'
import { IDNumber_dl } from '@/utils/regular'
import { authSceneOptions } from '@/config'

const idCardValid = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入被授权身份证号码'))
  } else if (!IDNumber_dl.test(value)) {
    callback(new Error('请输入正确的身份证号'))
  } else {
    callback()
  }
}
export default {
  props: {
    order_id: {
      type: String,
      default: ''
    },
    info: {
      type: Object,
      default: () => { }
    }
  },
  components: {
    addDigitude
  },
  data() {
    return {
      authSceneOptions,
      radio: '',
      subjectList: [],
      sgdSubjectList: [],
      vipInfo: null,
      saveLoading: false,
      ruleForm: {
        name: '',
        id_card: ''
      },
      rules: {
        id_card: [
          { required: true, trigger: 'blur', validator: idCardValid }
        ],
        name: [{ required: true, message: '请输入被授权人姓名', trigger: 'blur' }],
      },
      auth_scene:[],
      conuser_id: ''
    }
  },
  methods: {
    formatDate,
    async UserVipInfo() {
      try {
        const res = await fetchUserVipInfo({
          order_id: this.order_id
        })
        if (res.code === 0) {
          this.vipInfo = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getList() {
      try {
        const res = await fetchUserList({
          type: this.info.goodsType,
          order_id: this.order_id
        })
        if (res.code === 0) {
          this.subjectList = res.data
          // 可授权公司数相等
          // if (this.vipInfo.contracts.length === this.vipInfo.cert_num) {
          //   // 待签署
          //   this.sgdSubjectList = this.subjectList.filter(m => m.is_rel === 1)
          // }
          this.sgdSubjectList = this.subjectList.filter(m => m.is_rel === 1)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleAdd() {
      this.$refs.DigitudeRef.showDia()
    },
    saveRuleForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.addContract()
        }
      });
    },
    async addContract() {
      try {
        this.saveLoading = true
        const res = await fetchAddContractUser(this.getAddParams())
        if (res.code === 0) {
          this.dialogVisible = false
          this.getList()
          this.$message.success('保存成功！')
        }
        this.saveLoading = false
      } catch (error) {
        this.saveLoading = false
        console.log(error)
      }
    },
    getAddParams() {
      const params = {
        type: this.info.goodsType,
        ...this.ruleForm
      }
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
.activate-wrap {
  padding-left: 24px;
  margin-bottom: 23px;

  .formDate {
    display: flex;
    flex-wrap: wrap;

    .row {
      margin: 0 30px 8px 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;

      .label {
        white-space: nowrap;
      }

      .el-input {
        width: 180px;
      }
    }
  }

  .select-wrap {
    display: flex;

    .label {
      white-space: nowrap;
      margin-right: 16px;
    }

    .flexEnd {
      .add-btn {
        padding: 0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0061F3;
      }
    }

    .el-radio-group {
      .el-radio {
        margin: 0 40px 14px 0;
      }
    }
    .el-checkbox-group {
      .el-checkbox {
        margin: 0 40px 14px 4px;
      }
}
  }

  .rowShow {
    display: flex;
    align-items: center;

    .el-form {
      display: flex;

      .el-form-item {
        margin: 0 15px 0 0;
        display: flex;

        .el-input {
          width: 180px;
        }
      }
    }
  }

  .redText {
    color: $theme-color;
    font-size: 12px;
  }
}
</style>
