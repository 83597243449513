<template>
  <el-dialog :close-on-click-modal="false" class="dialog-self" width="780px" title="激活授权书" :visible.sync="dialogVisible"
    :destroy-on-close="true" :append-to-body="true">
    <p class="tip"><i class="el-icon-warning-outline"></i>请您务必规范填写授权信息，填写有误将会给您带来版权风险!(授权主体公司需填写授权项目所属的公司)</p>
    <div class="activate-wrap">
      <ul class="formDate" v-if="JSON.stringify(info) !== '{}'">
        <li class="row">
          <span class="label">素材名称：</span>
          <span class="value">{{ info && info.name }}</span>
        </li>
        <li class="row">
          <span class="label">授权信息：</span>
          <span class="value" v-if="info">
            <span style="margin-right: 14px;" v-for="item in info.sk_id_arr" :key="item.sk_id">{{ item.sk_name }}</span>
          </span>
        </li>
      </ul>
      <!-- 授权主体公司 -->
      <div class="select-wrap">
        <span class="label">请选择授权主体公司：</span>
        <div class="flexEnd">
          <el-radio-group v-model="conuser_id">
            <!-- {{ `${item.name}${item.is_rel === 1 ? '(待签署)' : ''}` }} -->
            <el-radio :label="item.conuser_id" v-for="(item, idx) in subjectList" :key="idx"
              :class="item.is_rel === 1 ? 'redText' : ''">{{ item.name }}</el-radio>
            <el-button type="text" class="add-btn" @click="handleAdd">新增授权公司</el-button>
          </el-radio-group>
          <p class="redText">* 标红企业信息为当前会员绑定激活企业</p>
        </div>
      </div>
      <!-- 新增授权公司弹窗 -->
      <addDigitude ref="RetailDigitudeRef" @getList="getList" :goodsType="2">
      </addDigitude>
    </div>

    <!-- 授权渠道 -->
    <el-form ref="authorizedForm" @submit.native.prevent :model="authorizedForm" :rules="authorizedRules"
      label-width="100px" size="small">
      <el-form-item label="授权渠道" class="put_channel" prop="put_channel">
        <el-checkbox-group v-model="authorizedForm.put_channel">
          <!-- <p v-for="(item, index) in authChannelOptions" :key="index"> -->
          <el-checkbox v-for="(item, index) in authChannelOptions" :key="index" :label="item.value">{{ item.label }}</el-checkbox>
          <el-checkbox label="10">其他</el-checkbox>
          <!-- </p> -->
        </el-checkbox-group>
      </el-form-item>
      <div v-if="showOther">
        <!-- <el-input placeholder="请输入授权渠道" v-model="authorizedForm.other" maxlength="10" /> -->
        <!-- <p class="other-channel-tips"><el-icon name="warning-outline" />授权渠道只可填写一个，若填写多个授权渠道，默认第一个有效。</p> -->
        <div v-for="(item, index) in authorizedForm.other_channel" :key="`other_${index}`" class="channels">
          <div class="channel-wrap">
            <el-form-item :prop="`other_channel.${index}.channel`" :rules="authorizedRules.other_channel">
              <el-input placeholder="请输入授权渠道" v-model="item.channel" maxlength="20"></el-input>
            </el-form-item>
            <i v-if="!item.addHide || authorizedForm.other_channel.length == 1" class="el-icon-circle-plus-outline icons"
              @click="addChannel(index)"></i>
            <i v-if="authorizedForm.other_channel.length > 1" class="el-icon-remove-outline icons"
              @click="deleteChannel(index)"></i>
          </div>
        </div>
        <span class="other-channel-tips" v-show="showOther">为降低版权风险，请填写真实有效的渠道名称，最多可添加 10
          个其它渠道，有问题请咨询客服</span>
      </div>
      <el-form-item label="授权项目" prop="project">
        <el-input v-model="authorizedForm.project" placeholder="请输入项目名称" />
      </el-form-item>
      <el-form-item label="投放链接" prop="put_link">
        <el-input v-model="authorizedForm.put_link" placeholder="请输入投放视频链接" :maxlength="200" />
        <div style="color: #FF8E14; font-size: 12px">仅需填写一个渠道投放地址</div>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="handleSubmitInfo">确认激活授权</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { batchRetailCertificate } from '@/api/member'
import { authChannelOptions } from '@/config'
import { downloadAuth } from '@/api/common'
import { mapGetters } from 'vuex'
// import { send_eventApi } from '@/utils/common.js'
// import { formDateTime } from '@/utils/formDate.js'
import { formatDate } from '@/utils/utils'
import { fetchUserList } from '@/api/user'
import addDigitude from './addDigitude.vue'


export default {
  name: 'RetailAuthInfo',
  components: {
    addDigitude
  },
  props: {
    // 表示来源页面
    target: {
      type: String,
      default: ''
    },
  },
  data() {
    const validChannel = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入有效授权渠道'))
      }else {
        callback()
      }
    }
    const validProject = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入授权项目'))
      } else if (!isNaN(value) || /^[A-Za-z]*$/.test(value)) {
        callback(new Error('请填写正确的项目名称，填写有误将会给您带来版权风险'))
      } else {
        callback()
      }
    }
    return {
      subjectList: [],
      conuser_id: '',
      order_id: '',
      dialogVisible: false,
      authChannelOptions,
      authorizedForm: {
        put_channel: [],
        project: '',
        put_link: '',
        other_channel: [
          {
            channel: ''
          }
        ]
      },
      showOther: false,
      addTip: false,
      // disabled_putchannel: false,
      // disabledOther: false,
      authorizedRules: {
        put_channel: [
          { required: true, message: '请选择授权渠道', trigger: 'change' }
        ],
        other_channel: [
          { required: true, trigger: 'blur', validator: validChannel }
        ],
        project: [
          {
            required: true,
            validator: validProject,
            trigger: 'blur'
          }
        ]
      },
      info: null,
      cert_channel: '' // 埋点数据对应渠道
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
  },
  watch: {
    'authorizedForm.put_channel': {
      handler(newvalue) {
        let vm = this
        if (newvalue.length > 1) {
          this.addTip = true
        } else {
          this.addTip = false
        }
        if (newvalue.includes('10')) {
          this.showOther = true
        } else {
          this.showOther = false
          this.authorizedForm.other_channel = [{ channel: '' }]
        }
        // 筛选埋点所选的对应渠道名
        this.cert_channel = newvalue
          .map((item) => {
            let obj = vm.authChannelOptions.filter((i) => i.value == item)
            return obj.length ? obj[0].label : '其他'
          })
          .join(',')
      },
      immediate: true
    }
  },
  methods: {
    formatDate,
    // 获取
    async getList() {
      try {
        const res = await fetchUserList({
          type: 2,
          order_id: this.order_id
        })
        if (res.code === 0) {
          this.subjectList = res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleAdd() {
      this.$refs.RetailDigitudeRef.showDia()
    },
    /**
     * 处理打开模态框
     * @param info
     */
    handleOpenDialog(info) {
      // console.log(info);
      this.info = info
      this.order_id = info.order_id
      this.dialogVisible = true
      this.$nextTick(() => {
        this.getList()
        this.$refs.authorizedForm.resetFields()
        this.authorizedForm = {
          put_channel: [],
          project: '',
          put_link: '',
          other_channel: [
            {
              channel: ''
            }
          ]
        }
      })
    },
    /**
     * 处理关闭弹框
     * 2021-12-17 16:12:35
     * @author SnowRock
     */
    handleClose() {
      this.dialogVisible = false
    },
    // 添加渠道
    addChannel(i) {
      let obj = {
        channel: ''
      }
      if (this.authorizedForm.other_channel.length == 9) {
        obj.addHide = true
      }
      this.$refs['authorizedForm'].validateField(
        `other_channel.${i}.channel`,
        (valid) => {
          if (!valid) {
            this.authorizedForm.other_channel[i].addHide = true
            this.authorizedForm.other_channel.push(obj)
          }
        }
      )
    },
    // 移除渠道
    deleteChannel(i) {
      let len = this.authorizedForm.other_channel.length
      this.authorizedForm.other_channel.splice(i, 1)
      this.authorizedForm.other_channel[len - 2].addHide = false
    },
    /**
     * 处理提交信息
     * 2021-12-17 16:23:51
     * @author SnowRock
     */
    handleSubmitInfo() {
      const vm = this
      if (!this.conuser_id) {
        this.$message.error('请选择授权主体公司或新增授权公司')
      }
      vm.$refs.authorizedForm.validate(async (valid) => {
        if (!valid) return
        const project = vm.authorizedForm.project
        const put_link = vm.authorizedForm.put_link

        let put_channel = [] // 授权渠道
        // 勾选了其它渠道
        if (this.showOther) {
          let others = vm.authorizedForm.other_channel.map((i) => i.channel)
          put_channel = vm.authorizedForm.put_channel.filter((item) => {
            return item !== '10'
          })
          put_channel = put_channel.concat(others)
          vm.cert_channel = vm.cert_channel + ',' + others.join(',')
        } else {
          put_channel = vm.authorizedForm.put_channel
        }
        const params = {
          order_id: vm.order_id,
          put_channel,
          project,
          put_link,
          conuser_id: ''
        }
        if (this.conuser_id) {
            params.conuser_id = this.conuser_id
          } else {
            this.$message.error('请选择授权主体公司')
          }
        if (!params.conuser_id) return
        // const obj = {
        //   m_type: '音乐',
        //   cert_channel: put_channel,
        //   cert_project: vm.cert_channel,
        //   op_date: formDateTime(),
        //   mid: object_id,
        //   user_name: this.userInfo ? this.userInfo.name : '',
        //   user_id: this.userInfo ? this.userInfo.user_id : ''
        // }
        // send_eventApi('e_certificate', obj)
        vm.submitLoading = true
        try {
          const { code, msg, data: { license_id }} = await batchRetailCertificate(params)
          if (code == 0) {
            await downloadAuth({ license_id })
            this.$store.state.download.auth.dialogVisibleAuth = true
            this.$store.dispatch('download/updateAuthDialogvisible', {
              dialogVisibleAuth: true,
              certifcateNum: 1000
            })
            vm.handleClose()
            if(!this.target) this.$parent.getRetailCertificatesList()
            else this.$parent.getOrderDetail()
          } else {
            // 选择多个渠道扣除对应下载次数时，如当天下载次数不足，点击确定时，提示今日剩余下载次数不足
            this.$message.error(msg)
            vm.handleClose()
          }
        } catch (e) {
          console.log('异常错误信息', e)
        }
        vm.submitLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tip {
  @include size(100%, 32px);
  background: rgba(255, 142, 20, 0.1);
  border: 1px solid #FF8E14;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-bottom: 24px;

  i {
    color: #FF8E14;
    margin-right: 4px;
  }
}

.put_channel {
  line-height: 40px;

  /deep/ {
    .el-radio-group {
      line-height: 40px;
    }
  }
}

/deep/.el-checkbox-group {
  p {
    display: inline-block;
    padding-right: 20px;
  }
}

.other-channel-tips {
  margin: 8px 0 20px 101px;
  font-size: 12px;
  color: #FF8E14;
}

.channel-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  /deep/.el-input__inner {
    width: 320px;
  }

  /deep/.el-form-item--small.el-form-item {
    margin-bottom: 0px;
  }

  .icons {
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
  }
}
  .public-tips{
    margin-left: 100px;
    color: #E52A0D;
    font-size: 12px;
  }

/deep/.el-dialog {
  .el-dialog__header {
    padding: 24px 36px 12px;
    border-bottom: 1px solid #F5F6FA;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }

  .el-dialog__body {
    padding: 19px 60px 22px;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 60px 32px;

    .el-button {
      height: 36px;
      border-radius: 2px;
      border: none;
      font-weight: 400;
      font-size: 14px;
      min-width: 0;
    }

    .el-button--default {
      width: 70px !important;
      background: #F2F2F2;
      color: rgba(0, 0, 0, 0.5);
    }

    .el-button--primary {
      width: 124px !important;
      background: #E52A0D;
    }
  }
}
.activate-wrap {
  padding-left: 24px;
  margin-bottom: 23px;

  .formDate {
    .row {
      margin: 0 30px 8px 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;

      .label {
        white-space: nowrap;
      }

      .el-input {
        width: 180px;
      }
    }
  }

  .select-wrap {
    display: flex;

    .label {
      white-space: nowrap;
      margin-right: 16px;
    }

    .flexEnd {
      .add-btn {
        padding: 0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0061F3;
      }
    }

    .el-radio-group {
      .el-radio {
        margin: 0 40px 14px 0;
      }
    }
  }

  .redText {
    color: $theme-color;
    font-size: 12px;
  }
}
</style>
