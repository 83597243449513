<template>
  <div class="authorized-download member-table-container container_media">
    <div class="rowShow title-row">
      <p class="card-title">我的授权</p>
      <ul class="tab-model">
        <li :class="activeName === 'member' ? 'tab active' : 'tab'" @click="handleTabClick('member')">
          <span>会员</span>
        </li>
        <li :class="activeName === 'retail' ? 'tab active' : 'tab'" @click="handleTabClick('retail')">
          <span>零售</span>
        </li>
      </ul>
    </div>

    <div class="author-container" v-show="activeName === 'member'">
      <div class="operation-container">
        <el-form :model="listQuery" :rules="rules" @submit.native.prevent ref="ruleForm">
          <el-form-item class="class-label" label="会员分类：" prop="code">
            <el-select v-model="listQuery.goods_type" size="small" placeholder="请选择会员分类" clearable>
              <el-option v-for="item in memberType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="code" class="ganged-item" v-if="listQuery.goods_type !== ''">
            <el-select v-model="listQuery.order_id" size="small" placeholder="请选择会员" clearable>
              <el-option v-for="item in listQuery.goods_type === 2 ? firmList : personageList" :key="item.order_id"
                :label="item.goods_name" :value="item.order_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="code" class="ganged-item" v-if="listQuery.goods_type !== ''">
            <el-select v-model="listQuery.status" size="small" placeholder="请选择授权书状态" clearable>
              <el-option v-for="item in authorizationStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="bPart">
          <div class="timeF">
            <span class="pel">下载时间：</span>
            <el-date-picker
              v-model="mem_create_at"
              valueFormat="yyyy-MM-dd"
              type="daterange"
              size="small"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
            <span class="search-btn" @click="search">查询</span>
          </div>
          <el-tooltip class="item" effect="dark" content="仅支持同一会员、同一主体下批量激活授权" placement="top">
            <el-button class="batch-btn" type="download" size="small" plain @click="handleBatch">批量授权</el-button>
          </el-tooltip>
        </div>
      </div>
      <p class="authorized-download-tips"><i
          class="el-icon-warning-outline warning-icon"></i><span>注意：为了规避版权风险，请在会员有效期内进行激活授权书，会员过期后对应下载素材无法进行激活授权</span>
      </p>
      <div class="myCenter_table">
        <el-table :data="list" header-cell-class-name="table_header" :cell-style="TableRowStyle"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center" :selectable="selectable">
          </el-table-column>
          <!-- <el-table-column label="序号" type="index" align="center" /> -->
          <el-table-column label="会员名称" prop="goods_name" width="120">
            <template slot-scope="{ row: {goods_name, goodsType}}">
              {{ `${goodsType == 1 ? '个人' : '企业'}-${goods_name}` }}
            </template>
          </el-table-column>
          <el-table-column label="会员状态">
            <template slot-scope="{ row: { expire_at } }">
              <span :class="new Date(expire_at).getTime() > new Date().getTime() ? 'valid' : 'novalid'">{{ new
                Date(expire_at).getTime() > new Date().getTime() ? '有效' : '已到期' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="素材名称" prop="object_name" />
          <el-table-column label="素材类型" prop="type" align="center">
            <template slot-scope="{ row: { object_type } }">
              {{ object_type | transitionValToLabel(GOODS_TYPE) }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="授权信息" prop="authInfo" align="center">
          <template slot-scope="{ row: { license_info } }">{{
            license_info
          }}</template>
        </el-table-column> -->
          <el-table-column label="授权项目名称" prop="project" align="center" width="110px" />
          <el-table-column label="授权渠道" prop="put_channel" align="center" width="200px" />
          <el-table-column label="下载时间" prop="create_at" align="center" width="200px" />
          <el-table-column label="授权书状态" prop="authInfo" align="center">
            <template slot-scope="{ row: { status } }">
              <span class="noActivate" v-if="status == 1"><i class="el-icon-warning"></i>待激活</span>
              <span class="activate" v-else><i class="el-icon-success"></i>已激活</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left">
            <template
              slot-scope="{ row: { license_id, certificate_id, object_id, object_type, order_id, goodsType, is_expired, goods_name, create_at, expire_at } }">
              <el-button type="text"
                @click="handleDownload(license_id, certificate_id, object_id, object_type, order_id, goodsType, !license_id && is_expired, goods_name, create_at, expire_at)"
                :class="{
                  'is-disabled disabled-text': !license_id && is_expired
                }" class="detail-text">
                {{ !license_id ? '激活授权' : '查看授权' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="vf-music-pagination" v-if="!listQuery.goods_type">
        <Pagination position="center" :limit.sync="listQuery.page_size" :page.sync="listQuery.page" :total="total"
          style="margin-top: 10px" @pagination="getMemberCertificatesList" />
      </div>
    </div>
    <div class="author-container" v-show="activeName === 'retail'">
      <div class="operation-container">
        <div class="timeF">
        <span class="pel">下载时间：</span>
        <el-date-picker
          v-model="retail_create_at"
          valueFormat="yyyy-MM-dd"
          type="daterange"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <span class="search-btn" @click="searchRetailList">查询</span>
      </div>
      </div>

      <div class="myCenter_table">
        <el-table :data="retailList" header-cell-class-name="table_header" :cell-style="TableRowStyle">
          <el-table-column label="素材名称">
            <template slot-scope="{ row: { extend: { name } } }">
              {{ name }}
            </template>
          </el-table-column>
          <el-table-column label="素材类型" align="left">
            <template slot-scope="{ row: { extend: { obj_type } } }">
              {{ obj_type | transitionValToLabel(GOODS_TYPE) }}
            </template>
          </el-table-column>
          <el-table-column label="授权信息" align="left">
            <template slot-scope="{ row: { extend: { sk_id_arr } } }">
              <div v-for="item in sk_id_arr" :key="item.sk_id">{{item.sk_name}}</div>
            </template>
          </el-table-column>
          <el-table-column label="授权项目名称" align="center" width="110px">
            <template slot-scope="{ row: { lic_info } }">
              {{lic_info && lic_info.length ? lic_info[0].project : ''}}
            </template>
          </el-table-column>
          <el-table-column label="授权渠道" prop="created_at" align="center" width="200px">
            <template slot-scope="{ row: { lic_info } }">
              {{lic_info && lic_info.length ? lic_info[0].put_channel : ''}}
            </template>
          </el-table-column>
          <el-table-column label="下载时间" prop="put_channel" align="center" width="200px">
            <template slot-scope="{ row: { created_at } }">
              {{created_at }}
            </template>
          </el-table-column>
          <el-table-column label="授权书状态" align="center">
            <template slot-scope="{ row: { extend: { is_licensed }  } }">
              <span class="noActivate" v-if="is_licensed == 0"><i class="el-icon-warning"></i>待激活</span>
              <span class="activate" v-else><i class="el-icon-success"></i>已激活</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="left">
            <template
              slot-scope="{ row: { extend : { license_id, sk_id_arr, name }, order_id } }">
              <el-button type="text"
                @click="handleRetailDownload(license_id, sk_id_arr, name, order_id)"
                class="detail-text">
                {{ !license_id ? '激活授权' : '查看授权' }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="vf-music-pagination">
        <Pagination position="center" :limit.sync="retailListQuery.page_size" :page.sync="retailListQuery.page" :total="retailTotal"
          style="margin-top: 10px" @pagination="getRetailCertificatesList" />
      </div>
    </div>
    <ReatailAuthInfo ref="reatailAuthInfoRef"></ReatailAuthInfo>

    <!-- 授权协议-->
    <CompleteSingeMusicAuthInfo ref="CompleteSingeMusicAuthInfo" />

    <!-- 授权激活成功提示弹框 -->
    <AuthsuccessDialog :dialogVisibleAuth="dialogVisibleAuth" :certifcateNum="certifcateNum" />

    <!-- 授权激活成功提示弹框 -->
    <AuthtimeslimitDialog :dialogVisibleAuthTimes="dialogVisibleAuthTimes" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { fetchMemberCertificatesList, fetchRetailCertificatesList } from '@/api/member'
import { GOODS_TYPE } from '@/enum/common'
import CompleteSingeMusicAuthInfo from '@/views/member/components/CompleteSingeMusicAuthInfo'
import ReatailAuthInfo from '@/views/member/components/RetailAuthInfo'
import AuthsuccessDialog from '@/views/member/components/AuthsuccessDialog'
import AuthtimeslimitDialog from '@/views/member/components/AuthtimeslimitDialog'
import { downloadAuth } from '@/api/common'
import { mapGetters } from 'vuex'
// import { fetchUserVipInfo } from '@/api/user'
import { memberType, authorizationStatus } from '@/config'
import { fetchUserVips } from '@/api/user'

export default {
  name: 'Invoice',
  components: {
    CompleteSingeMusicAuthInfo,
    ReatailAuthInfo,
    Pagination,
    AuthsuccessDialog,
    AuthtimeslimitDialog
  },
  watch: {
    'listQuery.goods_type': {
      handler(newV) {
        this.listQuery.order_id = ''
        if (!newV) {
          this.listQuery.status = ''
        } else {
          this.listQuery.status = 1
        }
      }
    }
  },
  data() {
    return {
      activeName: 'member',
      memberType,
      authorizationStatus,
      GOODS_TYPE: [...Object.values(GOODS_TYPE)],
      list: [],
      retailList: [],
      total: 0,
      listQuery: {
        page: 1,
        page_size: 10,
        goods_type: '',
        order_id: '',
        status: ''
      },
      mem_create_at: [],
      retail_create_at: [],
      retailTotal: 0,
      retailListQuery: {
        page: 1,
        page_size: 10,
      },
      rules: {},
      personageList: [], // 个人会员list
      firmList: [], // 企业会员list
      tipShow: false,
      submitLoading: false,
      multipleSelection: []
    }
  },
  created() {
    this.UserVipInfo()
    this.getMemberCertificatesList()
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    dialogVisibleAuth() {
      return this.$store.state.download.auth.dialogVisibleAuth
    },
    certifcateNum() {
      return this.$store.state.download.auth.certifcateNum
    },
    // 是否显示次数限制弹框
    dialogVisibleAuthTimes() {
      return this.$store.state.download.dialogVisibleAuthTimes
    }
  },
  methods: {
    // 切换tab
    handleTabClick(key){
      this.activeName = key
      this.listQuery.page = 1
      this.retailListQuery.page = 1
      this.mem_create_at = []
      this.retail_create_at = []
      if(key == 'member') this.getMemberCertificatesList()
      if(key == 'retail') this.getRetailCertificatesList()

    },
    searchRetailList() {
      this.retailListQuery.page = 1
      this.getRetailCertificatesList()
    },
    // 禁用已激活状态的项 及已过期的项
    selectable(row) {
      if (row.status !== 2 && new Date(row.expire_at).getTime() > new Date().getTime()) {
        return true
      }
    },
    // 绑定批量授权的授权ID
    getCertificateIds() {
      const list = []
      this.multipleSelection.forEach(item => {
        list.push(item.certificate_id)
      })
      return list.join(',')
    },
    // 批量授权
    handleBatch() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请选择待激活授权数据')
      } else {
        // 是否为同一会员
        const conditionVip = this.multipleSelection.every(item => item.order_id === this.multipleSelection[0].order_id)
        if (conditionVip) {
          const { certificate_id,
            object_id,
            object_type,
            goodsType,
            order_id,
            goods_name,
            create_at,
            expire_at } = this.multipleSelection[0]
          this.$refs.CompleteSingeMusicAuthInfo.handleOpenDialog({
            certificate_id,
            object_id,
            object_type,
            goodsType,
            order_id,
            goods_name,
            create_at,
            expire_at
          }, true, this.getCertificateIds())
        } else {
          this.$message.warning('请选择同一会员下的素材')
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async UserVipInfo() {
      try {
        const res = await fetchUserVips({})
        if (res.code === 0) {
          this.personageList = res.data.filter(item => item.goods_type === 1 && new Date(item.end_at).getTime() > new Date().getTime())
          this.firmList = res.data.filter(item => item.goods_type === 2 && new Date(item.end_at).getTime() > new Date().getTime())
        }
      } catch (error) {
        console.log(error)
      }
    },
    // row
    TableRowStyle({ column, rowIndex }) {
      let tableRowStyle = "";
      if (rowIndex % 2 !== 0) {
        tableRowStyle = tableRowStyle + "background-color: #FAFAFA;border-radius: 2px;";
      }
      // 内容折行
      if (!column.realWidth) {
        tableRowStyle = tableRowStyle + "padding: 14px 0;";
      }
      if(this.activeName == 'retail') {
        tableRowStyle = tableRowStyle + "padding: 14px 0;";
      }
      return tableRowStyle;
    },
    search() {
      this.listQuery.page = 1
      this.getMemberCertificatesList()
    },
    /**
     * 获取会员授权下载列表
     * 2021-12-24 17:05:06
     * @author SnowRock
     */
    getMemberCertificatesList() {
      let params = {...this.listQuery}
      if(this.mem_create_at && this.mem_create_at.length){
        params.create_at = {}
        params.create_at.begin = this.mem_create_at[0]
        params.create_at.end = this.mem_create_at[1]
      }
      fetchMemberCertificatesList(params).then((res) => {
        const { list, total } = res.data
        // console.log(list,'授权信息')
        this.list = list
        this.total = total
      })
    },
    // 获取零售授权下载列表
    getRetailCertificatesList() {
      let params = {...this.retailListQuery}
      if(this.retail_create_at && this.retail_create_at.length){
        params.create_at = {}
        params.create_at.begin = this.retail_create_at[0]
        params.create_at.end = this.retail_create_at[1]
      }
      fetchRetailCertificatesList(params).then((res) => {
        const { list, total } = res.data
        // console.log(list,'授权信息')
        this.retailList= list
        this.retailTotal = total
      })
    },
    /**
     * 下载授权书
     */
    async handleDownload(license_id, certificate_id, object_id, object_type, order_id, goodsType, is_expired, goods_name, create_at, expire_at) {

      if (is_expired) {
        this.$message.warning('您下载素材时使用的会员已到期，该素材不可激活授权书。');
        return
      }
      if (!license_id) {
        try {
          // 判断是否该授权对应的套餐填写了协议
          // const { data } = await fetchUserVipInfo({
          //   order_id: order_id
          // })
          // console.log(data, '是否授权')
          // if (data.contract_id.length == 0) {
          //   this.$message.warning('需在【我的会员】下载订阅服务协议后才可下载授权书');
          //   return;
          // } else {
          this.$refs.CompleteSingeMusicAuthInfo.handleOpenDialog({
            certificate_id,
            object_id,
            object_type,
            goodsType,
            order_id,
            goods_name,
            create_at,
            expire_at
          })
          // }
        } catch (e) {
          console.log(e)
        }
      } else {
        downloadAuth({ license_id })
      }
    },
    // 零售下载授权书
    async handleRetailDownload(license_id, sk_id_arr, name, order_id){
      if (!license_id) {
        try {
          this.$refs.reatailAuthInfoRef.handleOpenDialog({sk_id_arr, name, order_id})
        } catch (e) {
          console.log(e)
        }
      } else {
        downloadAuth({ license_id })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.authorized-download {
  padding: 40px 0;
  .title-row {
    margin: 10px 0 40px;
  }
  .rowShow {
    display: flex;
    align-items: center;
  }
  .card-title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    margin-right: 40px;
  }
  .tab-model {
    display: flex;
    align-items: center;
    cursor: pointer;

    .tab {
      // width: 92px;
      padding: 0 18px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 20px;
    }

    .active {
      background: rgba(229, 42, 13, 0.1);
      font-weight: 600;
      color: #E52A0D;
    }
  }

  /deep/.author-container {
    background: #FFFFFF;
    // box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 40px;
    padding: 30px 36px;

    .class-label {
      display: flex;
      align-items: center;

      .el-form-item__label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .el-table {

      .noActivate,
      .activate {
        display: block;
        width: 62px;
        height: 24px;
        background: rgba(255, 142, 20, 0.1);
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF8E14;
        margin: 0 auto;

        i {
          margin-right: 2px;
        }
      }

      .activate {
        background: rgba(0, 173, 118, 0.1);
        color: #00AD76;
      }
    }
  }

  .authorized-download-tips {
    width: 100%;
    height: 32px;
    padding-left: 26px;
    background: rgba(255, 142, 20, 0.1);
    border: 1px solid #FF8E14;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .warning-icon {
      margin-right: 6px;
      font-size: 12px;
      color: #FF8E14;
    }
  }

  .operation-container {
    margin-bottom: 20px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .bPart{
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .timeF{
      display: flex;
      align-items: center;
      span.pel{
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .tip {
      margin-bottom: 15px;
      font-size: 13px;
    }

    .el-input {
      width: 300px;
      margin-right: 20px;
      height: 30px;
      display: block;
    }

    .el-button {
      height: 30px;
      width: 100px;
    }

    .search-btn {
      cursor: pointer;
      width: 60px;
      height: 32px;
      @include center-middle-flex;
      border-radius: 4px;
      border: 1px solid #E52A0D;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #E52A0D;
      margin-left: 16px;

      &:hover {
        background: #E52A0D;
        color: #FFFFFF;
      }
    }

    .batch-btn {
      width: 96px;
      height: 32px;
      background: #E52A0D;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }

    /deep/.el-form {
      display: flex;
      align-items: center;

      .el-form-item {
        margin-bottom: 0;

        .el-form-item__label {
          font-size: 15px;
          font-weight: 500;
          text-align: left;

          &::before {
            content: '';
          }
        }
      }

      .ganged-item {
        .el-form-item__content {
          margin-left: 20px !important;
        }
      }
    }
  }
}

.vf-music-pagination {
  margin-top: 40px;
}

.el-select-dropdown__item {
  padding: 0 15px;
}
</style>
