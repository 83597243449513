<template>
  <el-dialog
    :visible="dialogVisibleAuthTimes"
    class="dialog-self"
    width="520px"
    :destroy-on-close="true"
    top="20vh"
    append-to-body
    :before-close="handleCancel"
    center
  >
    <div class="dialog-title-self" slot="title">
      <div class="title">提示</div>
    </div>
    <div class="tips-container">
      <div>
        您今天的下载次数不足，可开通新会员后再激活授权书，也可以明天再来激活授权书。
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">明天再来</el-button>
        <el-button type="primary" @click="openMember">开通会员</el-button>
        <!-- <el-button type="primary" @click="openMember">升级会员</el-button> -->
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AuthDialog',
  data() {
    return {
      activateNum: 10000
    }
  },
  props:{
    dialogVisibleAuthTimes: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    /**
     * 关闭弹框
     */
    handleCancel() {
        this.$store.dispatch('download/updateAuthtimesDialog', false)
    },
    /***
     * 开通/升级会员
     */
    openMember(){
        this.$router.push({ path: '/package/page' });
        this.handleCancel();
    }
  }
}
</script>

<style lang="scss" scoped>

.tips-container {
  color: rgba(51, 51, 51, 1);
  padding-left: 0px;
  margin-bottom: 10px;
  word-break: break-all;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  button {
    display: inline;
    padding: 0 !important;
    margin: 0 !important;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
/deep/.el-dialog{
    .el-dialog__header{
        .el-dialog__headerbtn,.el-dialog__header{
            font-size: 25px!important;         
        }
    }
    .el-dialog__footer{
        .el-button{
            min-width: 130px!important;
        }
    }
}
   
</style>
